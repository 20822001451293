class DataTable extends HTMLFormElement {
    constructor() {
        super();

        // Ensure internal methods have correct context
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.updateState = this.updateState.bind(this);

        this.hasSelectedSome = false;
        this.hasSelectedAll = false;

        this.$downloadMenu = this.querySelector('[is="details-menu"]');
        this.$tagsMenu = this.querySelector('[is="select-menu"]');
        this.$shareMenu = this.querySelector('[data-testid="share-menu"]');
        this.$selectAllCheckbox = this.querySelector(
            ".js-select-all input[type=checkbox]"
        );
        this.$checkboxes = Array.from(
            this.querySelectorAll("table input[type=checkbox]")
        );

        // Ensure all checkboxes are unset if navigating back to a cached page.
        window.addEventListener("pageshow", () => {
            this.reset();
            this.updateState();
        });
        this.addEventListener("submit", this.handleSubmit);

        // Handle selecting rows
        if (this.$selectAllCheckbox) {
            this.$selectAllCheckbox.addEventListener("change", this.handleSelectAll);
        }
        this.$checkboxes.forEach(($checkbox) => {
            $checkbox.addEventListener("change", this.handleSelectRow);
        });

        // Set initial state
        this.updateState();
    }
    handleSubmit(event) {
        // Only submit the form via the keyboard if we're not highlighted on an input element.
        if (document.activeElement.tagName === "INPUT") {
            event.preventDefault();
        }
    }
    handleSelectAll() {
        this.hasSelectedAll = this.$selectAllCheckbox.checked;
        if (this.hasSelectedSome) {
            this.hasSelectedAll = false;
            this.hasSelectedSome = false;
        }

        this.$checkboxes.forEach(($checkbox) => {
            $checkbox.checked = this.hasSelectedAll;
        });
        this.updateState();
    }
    handleSelectRow() {
        const allChecked = this.$checkboxes.every(
            ($checkbox) => $checkbox.checked
        );
        const allUnchecked = this.$checkboxes.every(
            ($checkbox) => !$checkbox.checked
        );

        this.hasSelectedSome = !allChecked && !allUnchecked;
        if (allChecked && !this.hasSelectedAll) {
            this.hasSelectedAll = true;
        }
        if (allUnchecked && this.hasSelectedAll) {
            this.hasSelectedAll = false;
        }
        this.updateState();
    }
    getTagsForSelected() {
        const tags = new Map();

        // Get tags for each selected row and determine the default state for tags menu.
        const $selectedCheckboxes = this.$checkboxes.filter(
            ($checkbox) => $checkbox.checked
        );

        $selectedCheckboxes
            .filter($checkbox => $checkbox.dataset.tags)
            .forEach(($checkbox) => {
                $checkbox.dataset.tags
                    .split(",")
                    .filter((tag) => tag)
                    .forEach((tag) => {
                        if (tags.has(tag)) {
                            let count = tags.get(tag);
                            tags.set(tag, count + 1);
                        } else {
                            tags.set(tag, 1);
                        }
                    })
            });

        const mappedTags = {};
        const totalCount = $selectedCheckboxes.length;
        for (const [tag, count] of tags.entries()) {
            mappedTags[tag] = {};
            if (count === totalCount) {
                mappedTags[tag].checked = true;
            } else {
                mappedTags[tag].indeterminate = true;
            }
        }

        return mappedTags;
    }
    updateState() {
        if (this.$selectAllCheckbox) {
            this.$selectAllCheckbox.indeterminate = this.hasSelectedSome;
            this.$selectAllCheckbox.checked = this.hasSelectedAll;
        }

        const shouldDisable = !this.hasSelectedSome && !this.hasSelectedAll;
        if (this.$downloadMenu && typeof this.$downloadMenu.setDisabled === 'function') {
            this.$downloadMenu.setDisabled(shouldDisable);
        }
        if (this.$shareMenu && typeof this.$shareMenu.setDisabled === 'function') {
            this.$shareMenu.setDisabled(shouldDisable);
        }
        if (this.$tagsMenu && typeof this.$tagsMenu.setDisabled === 'function') {
            this.$tagsMenu.setDisabled(shouldDisable);
            const tagMenuState = this.getTagsForSelected();
            this.$tagsMenu.setChecked(tagMenuState);
        }

    }
}

export default DataTable;
