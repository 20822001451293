/* Use custom elements in all popular browsers */
import "@ungap/custom-elements";

import "htmx.org";

import Alpine from 'alpinejs';

// Make Alpine available globally
window.Alpine = Alpine;
Alpine.start();

import Button from "govuk-frontend/govuk-esm/components/button/button.mjs";

const $buttons = document.querySelectorAll('[data-module="govuk-button"]');
$buttons.forEach(($button) => {
    new Button($button).init();
});

import Details from "govuk-frontend/govuk-esm/components/details/details.mjs";

const $details = document.querySelectorAll('[data-module="govuk-details"]');
$details.forEach(($detail) => {
    new Details($detail).init();
});

import { Tabs } from "govuk-frontend";
const $tabs = document.querySelectorAll('[data-module="govuk-tabs"]');
$tabs.forEach(($tab) => {
    new Tabs($tab).init();
});

import ErrorSummary from "govuk-frontend/govuk-esm/components/error-summary/error-summary.mjs";

const $errorSummary = document.querySelector(
    '[data-module="govuk-error-summary"]',
);
new ErrorSummary($errorSummary).init();

/* GPAS Components */
import ConfirmButton from "./components/confirm-button.js";
import DetailsMenu from "./components/details-menu.js";
import SelectMenu from "./components/select-menu.js";
import HeaderMenu from "./components/header-menu.js";
import DataTable from "./components/data-table.js";

if (!window.customElements.get("confirm-button")) {
    window.customElements.define("confirm-button", ConfirmButton, {
        extends: "dialog",
    });
}

if (!window.customElements.get("details-menu")) {
    window.customElements.define("details-menu", DetailsMenu, {
        extends: "details",
    });
}

if (!window.customElements.get("select-menu")) {
    window.customElements.define("select-menu", SelectMenu, {
        extends: "details",
    });
}

if (!window.customElements.get("data-table")) {
    window.customElements.define("data-table", DataTable, {
        extends: "form",
    });
}

if (!window.customElements.get("header-menu")) {
    window.customElements.define("header-menu", HeaderMenu, {
        extends: "details",
    });
}
